<template>
  <div>
    <h1 class="text-2xl font-semibold">Quà tặng của tôi</h1>
    <div class="mt-4">
      <div class="divide-y card px-4" v-if="gifts.length">
        <div
          class="flex md:space-x-6 space-x-4 md:py-5 py-3 relative"
          v-for="gift in gifts"
          :key="'gift' + gift.ID"
        >
          <a
            v-if="gift.status && gift.status.value !== 'unopened'"
            href="#"
            v-on:click="setCurrentGift(gift)"
            uk-toggle="target: #giftProductModal"
            class="md:w-64 md:h-40 w-36 h-24 overflow-hidden rounded-lg relative shadow-sm"
          >
            <img
              :src="gift.product && gift.product.images && gift.product.images[0] ? gift.product.images[0].image : 'https://static.bcdcnt.net/assets/images/courses/img-2.png'"
              alt=""
              class="h-full absolute inset-0 object-cover"
            />
          </a>
          <div v-if="gift.status && gift.status.value === 'unopened'" class="md:w-64 md:h-40 w-36 h-24 overflow-hidden rounded-lg relative shadow-sm">
            <img src="/assets/images/empty-bg.jpg" alt="" class="w-full h-full absolute inset-0 object-cover">
            <img src="/assets/images/bcdcnt/logo-core.png" class="w-12 h-12 uk-position-center" alt="">
          </div>
          <div class="flex-1 space-y-2">
            <a
              v-if="gift.status && gift.status.value !== 'unopened'"
              href="#"
              v-on:click="setCurrentGift(gift)"
              uk-toggle="target: #giftProductModal"
              class="md:text-xl font-semibold line-clamp-2"
            >
              {{ gift.post_title }}
            </a>
            <h2 v-if="gift.status && gift.status.value === 'unopened'" class="md:text-xl font-semibold line-clamp-2">{{ gift.post_title }}</h2>
            <p class="leading-6 pr-4 line-clamp-2n">{{ gift.status && gift.status.value !== 'unopened' ? gift.product.post_title : 'Bạn hãy nhấn nút "Mở quà" để mở món quà này ra nhé'}}</p>
            <div v-if="gift.status.value === 'receiving'">
              <div v-if="gift.product && gift.product.attributes && gift.product.attributes.length">
                <p v-for="attribute in gift.product.attributes" :key="'attribute' + gift.product.ID + attribute.name">
                  {{ attribute.label }}: <strong>{{ getAttributeValueName(attribute, gift) }}</strong>
                </p>
              </div>
              <p>Người nhận: <strong>{{ gift.receiver }}</strong></p>
              <p>SĐT: <strong>{{ gift.phone }}</strong></p>
              <p>Địa chỉ: <strong>{{ gift.address }}</strong></p>
              <p>Ghi chú: <strong>{{ gift.note }}</strong></p>
            </div>

            <div class="flex items-center justify-between">
              <a
                href="javascript:void(0)"
                v-on:click="openGift(gift)"
                v-if="gift.status && gift.status.value === 'unopened'"
                class="flex items-center justify-center h-9 px-8 rounded-md border bg-blue-600 text-white"
                >Mở quà
              </a>
              <a
                href="#"
                v-on:click="setCurrentGift(gift)"
                uk-toggle="target: #receivingGiftModal"
                v-if="gift.status && gift.status.value === 'opened'"
                class="flex items-center justify-center h-9 px-8 rounded-md border bg-green-600 text-white"
                >Nhận quà
              </a>
              <a
                href="#"
                v-on:click="setCurrentGift(gift)"
                uk-toggle="target: #receivingGiftModal"
                v-if="gift.status && gift.status.value === 'receiving'"
                class="flex items-center justify-center h-9 px-8 rounded-md border bg-yellow-600 text-white"
                >Đã gửi yêu cầu nhận quà
              </a>
              <span
                v-if="gift.status && (gift.status.value === 'received_information' || gift.status.value === 'preparing' || gift.status.value === 'shipped' || (gift.status.value === 'received' && gift.received_at))"
                class="px-2 py-1 text-xs rounded-full bg-gray-500 text-white"
                >{{ gift.status.label }}
              </span>
              <a
                href="#"
                uk-toggle="target: #receivedGiftModal"
                v-on:click="setCurrentGift(gift)"
                v-if="gift.status && (gift.status.value === 'shipped' || (gift.status.value === 'received' && !gift.received_at))"
                class="flex items-center justify-center h-9 px-4 rounded-md border bg-green-600 text-white"
                >Xác nhận đã nhận quà
            </a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!gifts.length">
        Bạn chưa có quà tặng nào
      </div>
    </div>

    <receiving-gift-modal :gift="currentGift" v-on:receivingGiftDone="handleReceivingGiftDone"/>
    <received-gift-modal :gift="currentGift" v-on:receivedGiftDone="handleReceivedGiftDone"/>
    <gift-product-modal :gift="currentGift"/>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import ReceivingGiftModal from "./ReceivingGiftModal";
import ReceivedGiftModal from "./ReceivedGiftModal";
import GiftProductModal from "./GiftProductModal";

export default {
  name: "MyAccount",
  components: {ReceivingGiftModal, ReceivedGiftModal, GiftProductModal},
  data() {
    return {
      gifts: [],
      unopened: 0,
      currentGift: null
    };
  },
  methods: {
    loadMyGifts() {
      let query = `query {
        myGifts
      }`;

      ApiService.graphql(query)
        .then(({ data }) => {
          if (data.data && data.data.myGifts) {
            this.gifts = data.data.myGifts.gifts;
            this.unopened = data.data.myGifts.unopened;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    openGift(gift) {
      let query = `mutation($ID: ID!) {
        openGift(ID: $ID)
      }`;

      ApiService.graphql(query, {ID: gift.ID})
        .then(({ data }) => {
          if (data.data && data.data.openGift) {
            this.loadMyGifts();
            this.$toast.success("Mở hộp quà thành công. Vui lòng nhấn nút \"Nhận quà\" để nhận quà tặng");
          } else {
            this.$toast.error(data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
        });
    },
    setCurrentGift(gift) {
      this.currentGift = gift;
    },
    getAttributeValueName(attribute, gift) {
      if (gift && gift.attributes && attribute && attribute.values && attribute.values.length) {
        try {
          let attributes = JSON.parse(gift.attributes);
          if (typeof attributes[attribute.name] !== 'undefined') {
            for (var i = 0; i < attribute.values.length; i++) {
              if (attribute.values[i].name === attributes[attribute.name]) {
                return attribute.values[i].label;
              }
            }
          }
        } catch (e) {
          console.log(e);
        }
      }

      return '';
    },
    handleReceivingGiftDone() {
      this.loadMyGifts();
    },
    handleReceivedGiftDone() {
      this.loadMyGifts();
    }
  },
  mounted() {
    this.loadMyGifts();
  },
};
</script>
